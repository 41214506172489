<template>
    <b-modal
    class="modalCLass fan"
    id="modal-lg"
    size="md"
    centered
    hide-title
    hide-footer
    hide-header
    style="padding:0pc!important;"
    >
      <div>
        <b-tabs content-class="mt-3 bTabs" class="BTABSSS">
            <b-tab title="Sign In" active title-item-class="SignInTab">
              <!-- <template v-if="loggedInFan == 'Y'">
                <p class="fanPsize"> Success you are now logged in</p>
              </template>
              <template v-else> -->
                <div class="FanZoneSignIn-title">
                    <div class="FanTitleOuter">
                        <h2 class="FanTitle">Sunshine Tour Fan Zone </h2>
                        <h2 class="FanTitleSecond">SIGN IN</h2>
                    </div>
                </div> 
                <div class="outerFanPadSignIn">
                    <div>
                        <p class="fanPsize">Sign in to access your favourite players, book tickets, make the most overflow
                            your fan offers and see exculsive news and video coverage.
                        </p>
                    </div>
                    <b-form @submit.prevent="onSubmitSignIn" class="modalCLass">
                      <b-row v-for="(field, index) in initalFanData" :key="index">
                          <b-col lg='12'>
                              <b-form-group
                                  id="input-group-2"
                                  :label="field.label"
                                  label-for="input-2"
                                  class="formFont"
                                  v-if="field.type_display == 'password' || field.name == 'email' && field.mandatory == '1'"
                              >
                                  <template v-if="field.type_display == 'text'"> 
                                      <div class="outerFromSign">
                                          <b-form-input
                                              id="input-2"
                                              v-model="initalFanData[index].field_value"
                                              :placeholder="field.label"
                                              required
                                              class="inputFontSize"
                                          ></b-form-input>
                                      </div>
                                  </template>
                                  <template v-else-if="field.type_display == 'password'">
                                      <div class="outerFromSign">
                                          <b-form-input
                                              type="password"
                                              autocapitalize="off"
                                              autocorrect="off"
                                              ui-show-password
                                              id="input-2"
                                              v-model="initalFanData[index].field_value"
                                              :placeholder="field.label"
                                              required
                                              class="inputFontSize"
                                          ></b-form-input>
                                      </div>
                                  </template>
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <b-button type="submit" variant="primary" class="SignIn">Sign In</b-button>
                    </b-form>
                    <p @click="forgotPassModal()" class="forgottonPassword">Forgotten Password ?</p>
                </div>
                <div v-if="showPassModal == 'Y'" class="showTerms">
                  <h3 class="my-4 termsTitle">Password Recovery</h3>
                  <p class="my-4 termsContent">Please enter your e-mail address so your password can be sent to you</p>
                  <div data-v-50125484="" id="input-group-2" role="group" class="form-group formFont">
                    <label id="input-group-2__BV_label_" for="input-2" class="d-block">E-mail</label>
                    <div>
                      <div data-v-50125484="" class="outerFromSign">
                        <input v-model="recoverEmail" id="input-2" type="text" placeholder="E-mail" required="required" aria-required="true" class="inputFontSize form-control">
                      </div>
                    </div>
                  </div>
                  <div class="closeTerms" @click="closePass()">Close</div>
                  <div class="closeTerms" style="margin-right:10px " @click="forgottonPass()">OK</div>
                  <h3 v-if="status == 'OK'" class="messageOkay">{{message}}</h3>
                </div>
              <!-- </template> -->
            </b-tab>
            <b-tab title="Sign Up" title-item-class="SignUpTab">
                <template v-if="status == 'OK'">
                    <div class="statusmessage">
                        <p class="fanPsize"> {{statusMessage}} </p>
                    </div>
                </template>
                <template v-else>
                    <div class="FanZoneSignUp-title"> 
                        <div class="FanTitleOuter">
                            <h2 class="FanTitle">Sunshine Tour Fan Zone</h2>
                            <h2 class="FanTitleSecond">SIGN UP</h2>
                        </div>
                    </div>
                    <div class="outerFanPad">
                        <div>
                            <p class="fanPsize"><strong>Sign up to become a Sunshine Tour Fan.</strong> The exculsive fan zone allows
                            you to keep track of your favourite players, book event tickets, see exculsive news and video
                            coverage as well as recieve fan offers and discounts.
                            </p>
                        </div>
                        <b-form @submit.prevent="onSubmitSignUp" class="modalCLass ">
                            
                            <b-row v-for="(field, index) in initalFanData" :key="index">
                                <b-col lg='12'>
                                    <b-form-group
                                        id="input-group-2"
                                        :label="field.label"
                                        label-for="input-2"
                                        class="formFont"
                                        v-if="field.mandatory == '1' && field.module_name == 'Personal' && field.active == '1'"
                                    >
                                        <template v-if="field.type_display == 'text'"> 
                                            <div class="outerFromSign">
                                                <b-form-input
                                                    id="input-2"
                                                    v-model="initalFanData[index].field_value"
                                                    :placeholder="field.label"
                                                    required
                                                    class="inputFontSize"
                                                ></b-form-input>
                                            </div>
                                        </template>
                                        <template v-else-if="field.type_display == 'select'">
                                            <!-- <b-form-select v-model="initalFanData[index].field_value" :options="options" class="inputFontSize"></b-form-select> -->
                                            <b-form-select 
                                                :disabled="(field.key == '1')"
                                                v-model="initalFanData[index].field_value"
                                            >
                                                <b-form-select-option 
                                                    v-for="(opt, optIdx) in selectOpts[field.table_name + '-' + field.name]"
                                                    :key="optIdx"
                                                    :value="opt.code"
                                                    >{{opt.desc}}
                                                </b-form-select-option>
                                            </b-form-select>
                                        </template>
                                        <template v-else-if="field.type_display == 'datepick'">
                                            <!-- <b-form-datepicker id="example-datepicker" v-model="value" class="mb-2 inputFontSize"></b-form-datepicker> -->
                                            <input style="width:100%; height:35px" class="inputFontSize" type="date" v-model="initalFanData[index].field_value">
                                        </template>
                                        <template v-else-if="field.type_display == 'password'">
                                            <div class="outerFromSign">
                                                <b-form-input
                                                    type="password"
                                                    autocapitalize="off"
                                                    autocorrect="off"
                                                    ui-show-password
                                                    id="input-2"
                                                    v-model="initalFanData[index].field_value"
                                                    :placeholder="field.label"
                                                    required
                                                    class="inputFontSize"
                                                ></b-form-input>
                                            </div>
                                        </template>
                                        <template v-else-if="field.type_display == 'checkbox'">
                                          <b-form-checkbox
                                            id="checkbox-2"
                                            v-model="initalFanData[index].field_value"
                                            name="checkbox-2"
                                            value="true"
                                            unchecked-value="false"
                                          >
                                            <!-- I accept the terms and use -->
                                          </b-form-checkbox>
                                        </template>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="latestTerms">
                                <div class="TermsCond">
                                  <b-row>
                                    <b-col lg="2">
                                      <b-form-checkbox
                                          id="checkbox-1"
                                          v-model="status"
                                          name="checkbox-1"
                                          value="accepted"
                                          unchecked-value="not_accepted"
                                          class="inputFontSize"
                                      >
                                      </b-form-checkbox>
                                    </b-col>
                                    <b-col lg="10" class="termsText">
                                      I have read and accepted the <p @click="getTermsConditions()" class="terms"> terms and conditions </p>
                                    </b-col>
                                  </b-row>
                                </div>
                            </b-row>
                            <b-button type="submit" variant="primary" class="SignUp">Sign Up</b-button>
                        </b-form>
                    </div>
                    <div v-if="showTerms == 'Y'" class="showTerms">
                      <h3 class="my-4 termsTitle" v-html="docTitle"></h3>
                      <p class="my-4 termsContent" v-html="docContent"></p>
                      <div class="closeTerms" @click="closeTerms()">OK</div>
                    </div>
                </template>
            </b-tab>
        </b-tabs>
      </div>
    </b-modal>
</template>

<script>
import * as CryptoJS from 'crypto-js';
import axios from "axios";
export default {
  name: "Menu",
  props: ["foot", "config", "initalFanData", "latestTerms", 'selectOpts', 'latestTermsID'],
  components: {
  },
  data() {
    const CryptoJSAesJson = {
      'stringify': (cipherParams) => {
        const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};

        if (cipherParams.iv)
          j.iv = cipherParams.iv.toString();

        if (cipherParams.salt)
          j.s = cipherParams.salt.toString();

        return JSON.stringify(j);
      },
      'parse': (jsonStr) => {
        const j = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});

        if (j.iv)
          cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);

        if (j.s)
          cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);

        return cipherParams;
      },
    };
    return {
      status: 'not_accepted',
      scrollPosition: null,
      form: {
        user: "",
        password: "",
        forename: "",
        surname: "",
        email: "",
        gender: null,
        dob: ""
      },
      email: "",
      password:"",
      email_string: "",
      password_string:"",
      genders: [{ text: 'Select Gender', value: null }, 'Male', 'Female',],
      options: [
        { value: 'M', text: 'Male' },
        { value: 'F', text: 'Female' },
      ],   
      checkUserTimer: null,
      userVerified: false,
      loginAuth: "",
      loggedInFan: 'Y',
      cryptoAES: CryptoJSAesJson,
      showTerms: '',
      showPassModal: '',
      recoverEmail: '',
      message: ''
    };
  },
  methods: {
    // CryptoJSAesJson: function() {
    //   const CryptoJSAesJson = {
    //     'stringify': (cipherParams) => {
    //       const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};

    //       if (cipherParams.iv)
    //         j.iv = cipherParams.iv.toString();

    //       if (cipherParams.salt)
    //         j.s = cipherParams.salt.toString();

    //       return JSON.stringify(j);
    //     },
    //     'parse': (jsonStr) => {
    //       const j = JSON.parse(jsonStr);
    //       const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});

    //       if (j.iv)
    //         cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);

    //       if (j.s)
    //         cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);

    //       return cipherParams;
    //     },
    //   };
    //   this.cryptoAES = CryptoJSAesJson;
    //   console.log("this.cryptoAES")
    //   console.log(this.cryptoAES)
    // },
    encryptString: function(input, passw) {
      return CryptoJS.AES.encrypt(JSON.stringify(input), passw, {format: this.cryptoAES}).toString();
    },
    hashString(input) {
      return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex);
    },
    base64Decode(input) {
      const decStr = atob(input);
      const bytes = new Uint8Array(decStr.length);

      for (let i = 0; i < bytes.length; i++)
        bytes[i] = decStr.charCodeAt(i);

      return String.fromCharCode(...new Uint16Array(bytes.buffer));
    },
    forgotPassModal: function() {
      this.showPassModal = 'Y'
    },
    forgottonPass: function() {
      let post_string = ''
      this.email_string = "email=" + encodeURIComponent(btoa(this.recoverEmail)) + "&" 
      post_string += this.email_string
      // const ctx = this
      axios
        .post(
          this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=recover&" + post_string + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
          post_string, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then((response) => {
          this.status = response.data.status;
          console.log('329')
          if (this.status == 'OK') {
            this.message = response.data.message;
            this.showPassModal = 'Y'
          }
        })
    },
    getTermsConditions: function() {
      axios
        .get(
          "https://www.ocs-world.com/apps/marketing-terms.php?action=latest&tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&?randomadd=" +
            new Date().getTime()
        )
        .then((response) => {
          this.showTerms = 'Y';
          console.log("this.showTerms")
          console.log(this.showTerms)
          this.docTitle = this.base64Decode(response.data.documents.document.title);
          this.docContent = this.base64Decode(response.data.documents.document.content);
        });
    },
    closeTerms: function() {
      this.showTerms = 'N'
    },
    closePass: function() {
      this.showPassModal = 'N'
    },
    gofanZone: function(){
      this.$router.push({ name: "myprofile" });
      // console.log('fan')
    },
    getObjArray(input) {
      let output = [];

      if (Array.isArray(input))
        output = input;
      else
        output = [input];

      return output;
    },
    jsonValue(input) {
      let output = '';

      if (typeof input == 'string')
        output = input.trim();
      else if (['number','boolean'].includes(typeof input))
        output = input;

      return output;
    },

    getDateFromTimestamp(input, strFormat) {
      const dateObj = new Date(input);
      const days = this.$root.getDaysList(3);
      const months = this.$root.getMonthsList(true);

      let output = '';

      if (strFormat) {
        output += String(dateObj.getFullYear()) + '-';
        output += String(dateObj.getMonth() + 1).padStart(2, '0') + '-';
        output += String(dateObj.getDate()).padStart(2, '0');
      }
      else {
        output += days[dateObj.getDay()] + ' ';
        output += dateObj.getDate() + ' ';
        output += months[dateObj.getMonth()] + ' ';
        output += String(dateObj.getHours()).padStart(2, '0') + ':';
        output += String(dateObj.getMinutes()).padStart(2, '0');
      }

      return output;
    },
    getMonthsList(abbr, upper) {
      let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      months = months.map((val) => {
        if (abbr)
          val = val.substring(0, 3);

        if (upper)
          val = val.toUpperCase();

        return val;
      });

      return months;
    },
    getDaysList(abbr, upper, monFirst) {
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      if (monFirst)
        days.push(days.shift());

      days = days.map((val) => {
        if (abbr)
          val = val.substring(0, 3);

        if (upper)
          val = val.toUpperCase();

        return val;
      });

      return days;
    },
    getFormattedDate(inpDate, config) {
      const dateFmt = (this.appConfig.dateFormat || 'dmy').split('');
      const dateOut = [];
      const outDelim = (typeof config.delimiter != 'undefined') ? config.delimiter : '/';

      let dateObj = {};

      if (inpDate instanceof Date)
        dateObj = inpDate;
      else if (inpDate.length == 10 && inpDate.includes('-'));
        dateObj = new Date(inpDate);

      for (const i of dateFmt) {
        let dateItem = '';

        switch (i) {
          case 'd': {
            if (config.ordinalDate) {
              dateItem = this.getOrdinalString(dateObj.getDate());
            }
            else {
              if (config.leadZeros)
                dateItem = String(dateObj.getDate()).padStart(2, '0');
              else
                dateItem = String(dateObj.getDate());
            }

            break;
          }
          case 'm': {
            if (config.monthFormat == 'name') {
              dateItem = this.getMonthsList()[dateObj.getMonth()];
            }
            else if (config.monthFormat == 'number') {
              if (config.leadZeros)
                dateItem = String(dateObj.getMonth() + 1).padStart(2, '0');
              else
                dateItem = String(dateObj.getMonth() + 1);
            }

            break;
          }
          case 'y': {
            if (config.abbrYear)
              dateItem = String(dateObj.getYear());
            else
              dateItem = String(dateObj.getFullYear());

            break;
          }
        }

        dateOut.push(dateItem);
      }

      return dateOut.join(outDelim);
    },
    getOrdinalString(input, supScript) {
      if (isNaN(input))
        return input;
      else
        input = parseInt(input);

      const j = input % 10;
      const k = input % 100;

      let ord = 'th';

      if (j == 1 && k != 11)
        ord = 'st';
      else if (j == 2 && k != 12)
        ord = 'nd';
      else if (j == 3 && k != 13)
        ord = 'rd';

      if (supScript)
        return input + '<sup>' + ord + '</sup>';
      else
        return input + ord;
    },
    onSubmitSignUp: function(e) {
      e.preventDefault();
      let array = []
      let postobject = {}
      let passwordString = ''
      let emailString = ''
      let emailHash = ''
      let post_string = ''
      let index_update = 0
      // let elementDOB = ''
      this.initalFanData.forEach((element) => {
        if (element.mandatory == '1' && element.active == '1' && element.module_name == 'Personal') {
            post_string += "fields[" + index_update + "][table]="
            post_string += 'users_'+ this.config.VUE_APP_FAN_DATA_BASE + '&'

            post_string += "fields[" + index_update + "][name]="
            post_string += element.name  + '&'

            if(element.name == 'password') {
              post_string += "fields[" + index_update + "][value]="
              post_string += encodeURIComponent(btoa(this.encryptString(element.field_value, emailHash)))  + '&' 
            } 
            // else if(element.name == 'email') {
            //   post_string += "fields[" + index_update + "][value]="
            //   post_string += this.hashString(element.field_value)  + '&' 
            // } 
            else if (element.name !== 'dob') {              
              post_string += "fields[" + index_update + "][value]="
              post_string += element.field_value  + '&' 
            } else {
              post_string += "fields[" + index_update + "][value]="
              post_string +=  Math.floor(new Date(element.field_value).getTime() / 1000)   + '&' 

              localStorage.setItem('FullDOB', element.field_value )
              
            }
            
            if (element.name == 'email') {
                emailString = element.field_value
                postobject.email = emailString
                this.email = postobject.email
            }
            if (element.name == 'password') {
                passwordString = element.field_value
                emailHash = this.hashString(emailString)
                postobject.password = passwordString
                console.log("postobject.password")
                console.log(postobject.password)
                this.password = this.encryptString(postobject.password, emailHash)
                localStorage.setItem("password", this.password);
            }
            index_update = index_update +1
            // array.push(saveData)   
        }
        if (element.table_name == 'users_terms_' + this.config.VUE_APP_FAN_DATA_BASE) {
          console.log('IN USER TERMS')
          if (element.name == 'doc_id') {
              post_string += "fields[" + index_update + "][table]="
              post_string += element.table_name + '&'
              
              post_string += "fields[" + index_update + "][name]="
              post_string += element.name  + '&'
              
              post_string += "fields[" + index_update + "][value]="
              post_string += this.latestTermsID  + '&' 
          }

          if (element.name == 'email') {
              post_string += "fields[" + index_update + "][table]="
              post_string += element.table_name + '&'
              
              post_string += "fields[" + index_update + "][name]="
              post_string += element.name  + '&'
              
              post_string += "fields[" + index_update + "][value]="
              post_string += encodeURIComponent(btoa(this.email))  + '&' 
          }
          index_update = index_update +1
        }
      });
      this.email_string = "email=" + encodeURIComponent(btoa(postobject.email)) + "&" + "password=" + encodeURIComponent(btoa(this.password)) + "&"
      post_string += this.email_string
      this.Email = encodeURIComponent(btoa(postobject.email)) 
      localStorage.setItem("EmailKey", this.Email);
      // let fanFulldata = {
      //   email: emailString,
      //   fields: array
      // }
      console.log("post_string")
      console.log(post_string)
      console.log(array)
      axios
        .post(
          this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=register&access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
          post_string, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then((response) => {
          console.log("SENT 240 onsubmit")

          this.status = response.data.status;
          if (this.status == 'OK') {
            this.statusMessage = response.data.message;
            this.FanLoggedIn = 'Y';
            localStorage.setItem("FanLoggedIn", this.FanLoggedIn);
            // console.log("this.statusMessage")
            // console.log(this.statusMessage)
            // console.log("PASS")
            localStorage.setItem("Forname", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']);
            localStorage.setItem("Email", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-email']);
            localStorage.setItem("EmailUser", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-email']);
            localStorage.setItem("DOBUser", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-dob']);
            this.checkUserVerify()
            this.getUser();
          } else {
            console.log("FAIL PLEASE TRY AGAIN LATER")
          }
          return response;
        });
    },

    onSubmitSignIn: function(e) {
      e.preventDefault();
      const ctx = this
      let array = []
      let postobject = {}
      let emailString = ''
      let emailHash = ''
      let passwordString = ''
      let post_string = ''
      let index_update = 0
      this.initalFanData.forEach((element) => {
        if (element.mandatory == '1' && element.active == '1' && element.module_name == 'Personal') {
            post_string += "fields[" + index_update + "][name]="
            post_string += element.name  + '&'
            
            post_string += "fields[" + index_update + "][value]="
            post_string += element.field_value  + '&'

            if (element.name == 'email') {
                emailString = element.field_value
                postobject.email = emailString
                this.email = postobject.email
                localStorage.setItem("SavedEmail", this.email);
            }
            if (element.name == 'password') {
                passwordString = element.field_value
                emailHash = this.hashString(emailString)
                postobject.password = passwordString
                console.log("postobject.password")
                console.log(postobject.password)
                this.password = this.encryptString(postobject.password, emailHash)
                localStorage.setItem("Savedpassword", this.password);
            }
            
            index_update = index_update +1
            // array.push(saveData)   
        }
      });
      this.Email = encodeURIComponent(btoa(postobject.email)) 
      localStorage.setItem("EmailKey", this.Email);
      this.email_string = "email=" + encodeURIComponent(btoa(postobject.email)) + "&" + "password=" + encodeURIComponent(btoa(this.password)) + "&"
      post_string += this.email_string
      console.log("post_string")
      console.log(post_string)
      console.log(array)
      // const ctx = this
      axios
        .post(
          this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=select&" + post_string + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
          post_string, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then((response) => {
          // const checkUser = this.email;
          // const checkPass = this.password;
          this.status = response.data.status;
          console.log('329')
          if (this.status == 'OK') {
            this.FanLoggedIn = 'Y';
            localStorage.setItem("FanLoggedIn", this.FanLoggedIn);
            console.log('line 331')
            localStorage.setItem("Forname", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']);
            localStorage.setItem("EmailUser", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-email']);
            localStorage.setItem("DOBUser", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-dob']);
            ctx.getUser();
            ctx.gofanZone();
            ctx.loggedInFan == 'Y';
            ctx.$emit('LoggedIn', this.loggedInFan);
          }
         })
    },
    checkUserVerify :function() {
      const ctx = this
      // this.gofanZone()
      // console.log("this.email_string")
      // console.log(this.email_string)
      let email_verify_string = this.email_string
      let post_string = email_verify_string
      localStorage.setItem("Email", this.email_string);
      axios
        .post(
          this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=select&" + email_verify_string + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
          post_string, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then((response) => {
          console.log("SENT check user")
          this.status = response.data.status;
          if (this.status == 'OK') {
              console.log('STATUS OKAY 278')
              if (response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-login_auth'] == '1') {
                console.log("AUTH IN 279")
                this.loginAuth = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-login_auth']
                this.loginForename = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']
                console.log("this.loginForename -- 280")
                console.log(this.loginForename)
                ctx.$emit('Authorisied', this.loginAuth);
                ctx.gofanZone();
                this.fanUserData = response.data;
                this.AllData = response.data.fields.field;
                console.log("this.Userdata")
                console.log(this.Userdata)

                const fieldKey = 'users_' + this.config.VUE_APP_FAN_DATA_BASE;
                const userFields = this.getObjArray(this.AllData);

                this.fanUserData['fields'] = userFields;

                for (const i of userFields) {
                  if (i.table_name == fieldKey)
                    this.fanUserData[i.name] = this.jsonValue(response.data.user[fieldKey + '-' + i.name]);
                }

                localStorage.setItem('fan_user_data', JSON.stringify(this.fanUserData));
                // this.gofanZone()
                // this.gofanZone();
                ctx.userVerifiedSuccess();
              } else {
                console.log("Not Logged in")
              }
          }
          return response;
        });
        this.checkUserTimer = setTimeout(() => {
            this.checkUserVerify();
        }, 5 * 1000);
    },
    userVerifiedSuccess :function() {
        this.userVerified = true;
        clearTimeout(this.checkUserTimer);
    },
    getUser: function() {
      let post_string = '';
      let SavedEmail = localStorage.getItem("SavedEmail");
      this.SavedEmail = SavedEmail;
      // let email = this.hashString(this.SavedEmail)

      let Savedpassword = localStorage.getItem("Savedpassword");
      this.Savedpassword = Savedpassword;
      let password = this.encryptString(this.Savedpassword, this.SavedEmail)

      console.log("this.email_string 399")
      console.log(this.email_string)
      localStorage.setItem("Email", this.email_string);
      
      let EmailKey = localStorage.getItem("EmailKey");
      this.EmailKey = EmailKey;
      // let email_verify_string = this.email_string
      // let pass_verify_string = this.password_string
      // console.log("pass_verify_string")
      // console.log(pass_verify_string)

      this.email_string = "email=" + this.EmailKey + "&" + "password=" + encodeURIComponent(btoa(password)) + "&"
      post_string += this.email_string
      console.log("post_string -- ACTION = LOGIN")
      console.log(post_string)
      axios
        .post(
          this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=login&" + post_string + "&access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
          post_string, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then((response) => {
          console.log("SENT")

          this.status = response.data.status;
          if (this.status == 'OK') {
            console.log("Login Pass 411")
            this.fanUserData = response.data;
            this.AllData = response.data.fields.field;
            console.log("this.Userdata")
            console.log(this.Userdata)

            const fieldKey = 'users_' + this.config.VUE_APP_FAN_DATA_BASE;
            const userFields = this.getObjArray(this.AllData);

            this.fanUserData['fields'] = userFields;

            for (const i of userFields) {
              if (i.table_name == fieldKey)
                this.fanUserData[i.name] = this.jsonValue(response.data.user[fieldKey + '-' + i.name]);
            }

            localStorage.setItem('fan_user_data', JSON.stringify(this.fanUserData));

            
            
            localStorage.setItem("UserData", JSON.stringify(this.Userdata));
            localStorage.setItem("UserDataFieldsAll", JSON.stringify(this.AllData));
            localStorage.setItem("Forname", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']);
            localStorage.setItem("EmailUser", response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-email']);
            localStorage.setItem("DOBUser", response.data.user['users_'+ this.config.VUE_APP_FAN_DATA_BASE + '-dob']);
          } else {
            console.log('Error')
          }
          return response;
        });
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIFULL_URL +
          "tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.seasons = response.data.tm_params.season_code;
        this.course = response.data.code;
        this.reports = response.data.reports.reports_entry;
        this.apiClass = response.data.class_api;
        this.$emit("apiClass", { apiClass: this.apiClass });
        this.$emit("season", { season: this.seasons });
        this.$emit("course", { course: this.course });
        this.$emit("currentReport", { currentReport: this.currentReport });
        this.$emit("currentTitle", { currentTitle: this.currentTitle });
      });
      window.addEventListener('scroll', this.updateScroll);
  },

};
</script>

<style scoped>
h3.messageOkay {
  /* font-size: 11pt; */
  display: inline-flex;
  margin-top: 20px;
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
}
.termsText {
  font-size: 13px!important;
  color: #6C6C6C;
}
.closeTerms {
  background-color: #f8635f;
  border-color: #f8635f;
  color: #fff;
  font-size: 12px;
  float: right;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}
.closeTerms:hover {
  background-color: #93278f;
  border-color: #93278f;
  color: #fff;
  font-size: 12px;
  float: right;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}
.termsTitle {
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
}
.termsContent {
  font-size: 13px;
  color: #6C6C6C;
}
.showTerms {
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  padding: 15px;
  width: 100%;
  border: 5px solid #93278f;
}
p.forgottonPassword {
  margin-top: 35px;
  font-size: 13px;
  color: #6C6C6C;
  cursor: pointer
}
p.forgottonPassword:hover {
  margin-top: 35px;
  font-size: 13px;
  color: #93278f;
  cursor: pointer;
}
.statusmessage {
  padding: 20px;
}
::v-deep .TermsCond {
  /* padding: 20px; */
  padding: 15px;
}
.terms {
  color: #f8635f;
  cursor: pointer;
}
.terms:hover {
  color: #93278f;
  cursor: pointer;
}
::v-deep .SignInTab > a{
  background-color: #f8635f!important;
  color: #fff!important;
  border-color: transparent!important;
  border-top-right-radius: 0;
  font-weight: bold;
}
::v-deep .SignUpTab > a{
  background-color: #93278f!important;
  color: #fff!important;
  border-color: transparent!important;
  border-top-left-radius: 0;
  font-weight: bold;
}
::v-deep .BTABSSS > div > ul > li {
    width: 50%;
}
.nav-link.active.tab-active-class {
  background-color: red;
}
.outerFanPad {
  padding: 20px; 
  background: #F2F2F2;
}
.outerFanPadSignIn {
  padding: 20px; 
  background: #F2F2F2;
  height: 340px; 
}
.FanTitleOuter {
  padding: 20px;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem !important;
}
::v-deep .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0!important;
}
div#modal-lg___BV_modal_body_ {
  padding: 0;
}
.outerFromSign {
  display: inline-flex;
  width: 100%;
}
::v-deep div#modal-lg___BV_modal_body{
  padding: 0px;
}
h2.FanTitleSecond {
  font-size: 25px;
  font-weight: bold;
}
h2.FanTitle {
  font-size: 25px;
  font-weight: 200;
}
::v-deep .FanZoneSignUp-title {
  background-color: #93278f;
  color: #fff;
}
::v-deep .FanZoneSignIn-title {
  background-color: #f8635f;
  color: #fff;
}
::v-deep .inputFontSize {
  font-size: 13px!important;
}
::v-deep .formFont {
  font-size: 13px!important;
  color:#6C6C6C;
}
.fanPsize {
  font-size: 13px;
  color: #6C6C6C;
}
button.btn.SignUp.btn-primary {
  width: 100%;
  background-color: #93278f;
  border-color:#93278f;
}
button.btn.SignIn.btn-primary {
  width: 100%;
  background-color: #f8635f;
  border-color: #f8635f;
  position: relative;
  bottom: -20px;
}
.modal-dialog.modal-md.modal-dialog-centered {
  max-width: 500px;
}
::v-deep header#modal-2___BV_modal_header_ {
  display: none!important;
}
.coSantioned {
  color: #fff;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.fourthCourse-logo {
  width: 210px;
  margin-top: 25px!important;
  /* margin-top: 50px!important; */
  text-align: center;
  margin: auto;
  display: block;
}
img.thirdCourse-logo {
  margin-top: 45px!important;
  width: 170px;
  text-align: center;
  margin: auto;
  display: block;
}
.courseLogo-co {
  width: auto;
  text-align: center;
  margin: auto;
  display: block;
  margin-top: 35px;
  height: 60px;
}
.backImage-Co {
  background-repeat: no-repeat;
  background-size: cover;
  height: 130px; 
  background-color: rgba(32 66 50/70%);
  background-blend-mode: multiply;
}
li.nav-item.BuyTickets > a.nav-link.router-link-exact-active.router-link-active {
  color: #fff!important;
}
span.signInplayer > li > a:hover {
  color: #fff!important;
}
li.nav-item.BuyTickets:hover > a {
  background-image: none!important;
}
.buyTicketsBlock:hover {
  background-color: #027741;
}
i.nav-item.BuyTickets {
  border-bottom: 0px;
}
.buyTicketsBlock {
  background: #f8635f 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  top: 23px;
  left: 891px;
  width: 151px;
  height: 40px;
  text-align: center;
  font-size: 12px;
}
li.nav-item.BuyTickets {
  margin-top: 3px;
  margin-bottom: 3px;
  border-bottom: 0px;
}
::v-deep button.close.text-dark {
  color: #fff!important;
  font-weight: bold!important;
  font-size: 45pt!important;
  opacity: 1;
  margin-left: -15px;
  margin-top: 8px;
}
::v-deep .b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
  background: #93278f!important;
  height: 100%;
}
::v-deep .b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}
button.btn.logIN.btn-primary {
  background-color: #93278f;
  border-color: #93278f;
}
button.btn.logIN.btn-primary:hover {
  background-color: #93278f;
  border-color: #93278f;
}
.AlertNot {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  width: 300px;
  margin: auto;
  text-align: center;
  z-index: 999;
}
::v-deep span.signInplayer > li > a:hover {
  background-image: none!important;
}
::v-deep li.nav-item.BuyTickets > a {
  color: #fff!important;
}
::v-deep .playerSignin.playerSignInSroll > span.signInplayer > li > a:hover{
  color: #fff!important;
}
.thirdRow {
  /* margin-top: -54px; */
}
.fourthRow {
  /* margin-top: -110px; */
  margin-top: -56px;
}
button.navbar-toggler.btnWidth.collapsed {
  width: 91px;
}
button.navbar-toggler.btnWidth.not-collapsed {
  width: 91px;
}
.playerSignInSroll {
  /* background: #027741!important; */
}
.playerSignInSroll:hover{
  /* background: #f8635f!important; */
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}
img.tourImageNav {
  width: 150px;
  text-align: center;
  margin: auto;
  display: block;
  margin-top: 10px;
}
a.sstNOunderliner:hover {
  text-decoration: none;
}
.menu.DesktopMenu.fixedHead {
  position: fixed;
  width: 100%;
  background: #93278f !important;
  z-index: 99999;
  top: 0;
}
li.nav-item {
  border-bottom: 1px solid #027741;
  margin-bottom: 10px;
  margin-top: 10px;
}
.menuLeftTab {
  margin-left: initial!important;
  width: 17%;
  font-size: 13px;
}
.menuDrop {
  background-color: #93278f;
}
.containerMenuBlock {
  padding-top: 40px;
  padding-bottom: 50px;
}
p.sunshineTitle {
  margin-bottom: 0px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin-top: 4px;
}
p.sunshineTour {
  margin-bottom: 0px;
  text-align: center;
  color: #fff;
  font-weight: 600;
}
.RightMenuBlock {
  display: inline-flex;
}
.nopadNav {
  padding: 0rem 0rem;
}
.footerLogo {
  width: 100px;
  margin: auto;
  display: block;
}
.bigEasyback {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2024/06/Easy_BGgrey.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transition: transform .2s;
  background-repeat: no-repeat;
}
.ladiesTourback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/LAdies_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transition: transform .2s;
  background-repeat: no-repeat;
}
.seniorback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Senior_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transition: transform .2s;
  background-repeat: no-repeat;
}

.bigEasyback:hover {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2024/06/Easy_BGgrey.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transform: scale(0.995);
  background-repeat: no-repeat;
}
.ladiesTourback:hover {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/LAdies_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transform: scale(0.995);
  background-repeat: no-repeat;
}
.seniorback:hover {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Senior_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transform: scale(0.995);
  background-repeat: no-repeat;
}
.ourTours {
  color: #fff;
  font-size: 13px;
  position: relative;
  right: -14px;
}
.signInplayer {
  color: #FFFFFF;
  font-size: 12px;
  top: -6px;
  position: relative;
}
.signInplayer >li.nav-item {
  border-bottom: 0px solid #027741;
}
.playerSignin {
  background: #93278f 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  top: 23px;
  left: 891px;
  width: 151px;
  height: 40px;
  text-align: center;
  /* margin-left: 100px;
  margin-right: 100px; */
  margin-left: 50px;
  margin-right: 20px;
}
.playerSignin:hover {
  background: #f8635f 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  top: 23px;
  left: 891px;
  width: 151px;
  height: 40px;
  text-align: center;
  /* margin-left: 100px;
  margin-right: 100px; */
  margin-left: 50px;
  margin-right: 20px;
}
.menuWidth {
  width: auto;
  height: 22px;
}
.menuWidthTour {
  width: 15px;
  height: 15px;
}
span.logosMenuMob {
  display: flex;
}
::v-deep a.nav-link.router-link-exact-active.router-link-active {
  color: #efae16!important;
}
.lock {
  margin-left: 10px;
}
.loginPlayer > a {
  padding-right: 14px;
  display: inline-table;
  font-size: 7pt;
  padding-top: 22px !important;
  padding-bottom: 8px;
  border-radius: 0 0 10px 10px;
  background-color: #93278f !important;
  text-align: center;
  padding-left: 15px !important;
}
.topLevel > ul > li > a {
  padding: 0.5rem 0.5rem;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.covid {
  color: #ffffff;
  background-color: #cc0000;
  text-align: center;
  padding: 20px;
}
.covid > .row > .col > h4 {
  color: #ffffff;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.covid > .row > .col > p {
  margin: 0;
  font-size: 14px;
  padding-bottom: 20px;
}
.covid > .row > .col > a {
  padding: 10px 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9em;
  background-color: transparent;
  color: white !important;
}
#covidClose {
  display: block;
  opacity: 1;
  position: absolute;
  right: 1px;
  top: -20px;
  transform: scaleY(0.8);
  z-index: 1;
}
#covidClose > button {
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
  font-size: 40px;
  padding: 10px;
  display: block;
  line-height: 0.8;
  color: white !important;
}
.moreLogos {
  width: 80px;
}
.siteLogo {
  /* width: 200px;
  position: inherit;
  top: 16px;
  right: 80px; */
  width: 220px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.siteLogoRight {
  max-width: 60px;
  float: right;
  min-width: 60px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.headLink > .nav-link {
  color: #fff;
  font-size: 8pt !important;
}

.menuLink > .nav-link {
  color: white !important;
  font-weight: 500;
  font-size: 0.775rem;
}

::v-deep .dropdown-item {
  color: white !important;
  font-weight: 500;
}

::v-deep .dropdown-item:hover {
  color: #00b588 !important;
  background-color: #93278f;
}

::v-deep .dropdown-menu {
  background-color: #93278f;
}

::v-deep .dropdown-toggle::after {
  display: none;
}
::v-deep .dropdown-toggle {
  font-size: 14px;
}
::v-deep .dropdown-toggle > span {
  color: white !important;
  font-weight: 500;
}
.menuLink > .nav-link:hover {
  color: rgb(242, 175, 20) !important;
}
.work {
  margin: -170px -100px 0 150px;
  color: white;
}
.subImg {
  margin-left: -50px;
  margin-top: -50px;
}
.link {
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  max-width: 180px;
  font-weight: 300;
  text-align: center;
  background: rgb(0, 67, 62);
  margin: -4px 0px;
  padding: 10px;
}
.subscribe {
  width: 140%;
  height: 350px;
  background-color: #93278f !important;
}
.icon {
  padding-bottom: 7px;
  color: white;
}
.sIcon {
  background-color: #93278f;
  color: white !important;
}
#topNav {
  /* background-image: url('https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png'); */
  background-color: #93278f !important;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

#bottomNav {
  /* background-image: url('https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png'); */
  background-color: #93278f !important;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
::v-deep .dropdown > a {
  display: none;
}
.footLink > a {
  font-size: 9pt;
}
li {
  list-style-type: none;
}
li > a {
  color: white !important;
}
.mobShow {
  display: none;
}
.aboutInfo {
  background-color: #93278f !important;
  height: 350px;
}
.aboutBtn {
  display: block;
  background: #f8635f;
  border: none;
  color: #fff !important;
  border-radius: 0px;
  padding: 5px 20px 5px 20px;
  width: 200px;
  text-align: center;
  margin-top: 25px;
  float: right;
}
.aboutBtn:hover {
  background-color: #93278f;
}
.aboutText {
  color: #fff;
}
.aboutTitle {
  font-size: 24px;
  padding-bottom: 0.3em;
  line-height: 1.25;
  color: #f8635f;
}
@media only screen and (min-width: 1201px) {
  .MobileMenu {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .signInplayer {
    color: #FFFFFF;
    font-size: 13px;
    top: 4px;
    position: relative;
  }
  .DesktopMenu {
    display: none;
  }
  .playerSignin {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    text-align: left;
    margin-bottom: 20px;
  }
  .menuLeftTab {
    margin-left: initial!important;
    width: 100%;
  }
  .RightMenuBlock > button {
    background-color: transparent;
    border-color: transparent;
  }
}
@media only screen and (max-width: 1024px) {
  #nav-collapse {
    padding-left: 0;
  }
  .signInplayer {
    color: #FFFFFF;
    font-size: 13px;
    top: 5px;
    position: relative;
  }
}

@media only screen and (min-width: 991px) {
  .hideDeskLogos {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .signInplayer {
    color: #FFFFFF;
    font-size: 13px;
    top: 6px;
    position: relative;
  }
  ::v-deep .nopadNav {
    padding: 1.5rem 0rem;
  }
  ::v-deep .siteLogo {
    width: 200px;
    position: absolute;
    top: 6px;
  }
  .paddTop {
    padding-top: 20px;
  }
  li.nav-item.loginPlayer {
    display: none;
  }
  ul.navbar-nav {
    display: block;
  }
  span.hideDeskLogos {
    display: block;
    padding-left: 25px;
  }
  span.logosMenuMob {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .coSantioned {
    color: #fff;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: none;
  }
  .menuDrop {
    background-color: #93278f;
    margin-top: 25px;
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
    text-align: center;
  }
  .footLink > a {
    font-size: 16px;
  }
  .menuLink.mobShow {
    text-align: left;
  }
  .btn-group {
    position: relative;
    display: block;
  }

  .mobileMenu {
    position: absolute;
    padding: 0;
    background-color: #f8635f;
    top: -30px;
    left: -140px;
    height: 100vh;
    width: 100vh;
  }
  .siteLogoMenu {
    width: 180px;
    padding: 20px 35px;
  }
  ::v-deep .dropdown-toggle {
    background-color: transparent;
    border: 0;
  }

  .menuLink {
    padding: 6px 26px;
  }

  ::v-deep .menuLink > button {
    font-size: 21px;
  }

  .menuLink > .nav-link {
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .lock {
    margin-left: 0px;
    margin-top: 5px;
  }
  .menuDrop {
    background-color: #93278f;
    margin-top: 20px;
  }
  .playerSignin {
    width: 100%;
    margin-right: 30px;
  }
  .siteLogo {
    width: 170px!important;
    position: absolute;
    top: 6px;
  }
  .paddTop {
    padding-top: 20px;
  }
  span.logosMenuMob {
    display: none;
  }
  .aboutInfo {
    background-color: #93278f !important;
    height: auto;
  }
  .aboutBtn {
    display: block;
    background: #f8635f;
    border: none;
    color: #fff !important;
    border-radius: 0px;
    padding: 5px 20px 5px 20px;
    width: 100%;
    text-align: center;
    margin-top: 25px;
    float: right;
  }
  .greenNav {
    display: none;
  }

  #nav-collapse {
    padding-left: 0;
  }

  #logo {
    width: 90%;
  }
  .mobileMenu {
    top: -9px;
    left: -9px;
  }
}
</style>