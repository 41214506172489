<!-- Nav Menu -->

<template>
  <div id="app">
    <template v-if="$route.name == 'home'">
      <Menu
        @season="setSeason"
        @course="setCourse"
        @log="setLog"
        @currentReport="setReport"
        @currentTitle="setTitle"
        @apiClass="setapiClass"
        :foot="false"
        :config="env"
      />
      <transition name="fade" appear>
        <router-view
          :key="$route.fullPath"
          :season="season"
          :currentReport="currentReport"
          :course="course"
          :apiClass="apiClass"
          :title="currentTitle"
          :config="env"
          :loggedin='log'
      /></transition>
      <Footer
        :season="season"
        :currentReport="currentReport"
        :course="course"
        :apiClass="apiClass"
        :title="currentTitle"
        :config="env"
      />
    </template>
    <template v-else>
      <Menu
        @season="setSeason"
        @course="setCourse"
        @log="setLog"
        @currentReport="setReport"
        @currentTitle="setTitle"
        @apiClass="setapiClass"
        :foot="false"
        :config="env"
      />
      <transition name="fade" appear>
        <router-view
          :key="$route.fullPath"
          :season="season"
          :currentReport="currentReport"
          :course="course"
          :apiClass="apiClass"
          :config="env"
          :loggedin='log'
      /></transition>
      <Footer
        :season="season"
        :currentReport="currentReport"
        :course="course"
        :apiClass="apiClass"
        :title="currentTitle"
        :config="env"
      />
    </template>
  </div>
</template>

<style>
a {
  color: #337ab7 !important;
  text-decoration: none;
  background-color: transparent;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  /* font-family: "Open sans", sans-serif !important; */
  /* */
  font-family: sans-serif;
  /* background-color: rgb(249, 249, 249); */
  /* background: url(https://updated.sunshinetour.com/wp-content/uploads/2021/04/SunshineTourBG_2-scaled-1.jpeg); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-size: 1rem;
  /* font-weight: 400; */
  line-height: 1.5;
}
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */
@font-face {
  font-family: "Sans-Serif";
  src: url("/fonts/BAHNSCHRIFT.TTF") format("TTF"), url("/fonts/BAHNSCHRIFT.TTF")
      format("TTF");
}
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap'); */
li.nav-item::marker {
  color: transparent;
}
</style>
<script>
  <meta name="apple-itunes-app" content="app-id=419098478"></meta>
</script>
<script>
import Menu from "@/components/menu.vue";
import Footer from "@/components/footer.vue";

export default {
  components: {
    Menu,
    Footer,
  },
  data() {
    return {
      course: "",
      currentReport: "",
      season: "",
      currentTitle: "",
      env:[],
      log:''
    };
  },
  mounted() {
    this.env = process.env
  },
  metaInfo: {
    title: 'Sunshine Ladies Tour ',
    titleTemplate: '%s | Sunshine Ladies Tour '
  },
  methods: {
    // Define method that will use the payload to update the data property
    setSeason(payload) {
      this.season = payload.season;
    },
    setCourse(payload) {
      this.course = payload.course;
    },
    setReport(payload) {
      this.currentReport = payload.currentReport;
    },
    setTitle(payload) {
      this.currentTitle = payload.currentTitle;
    },
    setLog(payload) {
      this.log = payload.log;
    },
    setapiClass(payload) {
      this.apiClass = payload.apiClass;
    },
  },
};
</script>
